import "./App.css";
import { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import { API_URL } from "./config";
import axios from "axios";

function App() {
  const [img, setImg] = useState(undefined);
  const inputRef = useRef();

  const [imagga, setImagga] = useState([]);
  const [mippy, setMippy] = useState([]);
  const [result, setResult] = useState([]);
  const [result2, setResult2] = useState({
    total: 0,
    match: 0,
    mismatch: 0,
  });

  useEffect(() => {
    setResult2({
      match: result.filter((v) => v.match).length,
      mismatch: result.filter((v) => !v.match).length,
      total: result.length,
      matchRate: (
        (result.filter((v) => v.match).length / result.length) *
        100
      ).toFixed(2),
    });
  }, [result]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          padding: "0 20px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ color: "white", fontSize: "20px", padding: "0 0 20px 0" }}
        >
          ARTSHARING object test
        </div>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1.5fr repeat(3, 1fr)",
            justifyContent: "space-between",
            gap: "20px",
            textAlign: "center",
          }}
        >
          <div>
            <input
              type={"file"}
              hidden={true}
              multiple={false}
              required={true}
              ref={inputRef}
              accept={"image/png,image/jpeg,image/jpg"}
              onChange={async (event) => {
                URL.revokeObjectURL(img);
                if (event.target.files.length > 0) {
                  setImg(null);
                  await new Promise((resolve) => {
                    setTimeout(() => resolve(), 3000);
                  });
                  const formData = new FormData();
                  formData.append("image", event.target.files[0]);

                  const response = await axios.post(
                    `${API_URL}/artworks/upload`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    },
                  );

                  setImg(URL.createObjectURL(event.target.files[0]));
                  setImagga(response.data.imagga);
                  setMippy(response.data.artsharing);
                } else {
                  setImg(undefined);
                }
              }}
            />
            <div
              style={{
                width: "100%",
                aspectRatio: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "grey",
              }}
            >
              {img === undefined ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : img === null ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ClipLoader color={"#FFFFFF"} />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundImage: `url(${img})`,
                    backgroundColor: "black",
                  }}
                />
              )}
            </div>
            <div
              style={{
                padding: "20px 0",
              }}
            >
              <button
                style={{ width: "33%" }}
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                Upload
              </button>
            </div>
            <div>
              <button
                style={{ width: "33%" }}
                onClick={() => {
                  const arr = [];
                  for (const v of imagga) {
                    if (result.findIndex((v2) => v.tag === v2.tag) >= 0) {
                      continue;
                    }
                    if (v.score >= 80) {
                      if (
                        mippy?.object.findIndex((v2) => v.tag === v2.tag) >= 0
                      ) {
                        arr.push({ tag: v.tag, match: true });
                      } else {
                        arr.push({ tag: v.tag, match: false });
                      }
                    }
                  }
                  // setResult([...result, ...arr]);
                  setResult(arr);
                }}
              >
                Test
              </button>
            </div>
          </div>
          <div
            className={"hide-scrollbar"}
            style={{
              backgroundColor: "white",
              overflowY: "scroll",
            }}
          >
            <div>IMAGGA</div>
            <div
              style={{
                padding: "10px 20px 0",
              }}
            >
              {imagga?.map((v) => {
                return (
                  <div
                    key={`imagga_${v.tag}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: `${v.score >= 80 ? "blue" : "black"}`,
                    }}
                  >
                    <div>{v.tag}</div>
                    <div>{v.score}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={"hide-scrollbar"}
            style={{
              backgroundColor: "white",
              overflowY: "scroll",
            }}
          >
            <div>MIPPY</div>
            <div
              style={{
                padding: "10px 20px 0",
                textAlign: "left",
              }}
            >
              {mippy?.object?.map((v) => {
                return (
                  <div
                    key={`object_${v.tag}`}
                    style={{
                      color: `${v?.match ? "blue" : "black"}`,
                    }}
                  >
                    <div>{v.tag}</div>
                  </div>
                );
              })}
              {mippy?.mood && (
                <div
                  style={{
                    padding: "10px 0 0 0",
                  }}
                >
                  Moods
                </div>
              )}
              <div
                style={{
                  padding: "0 0 0 20px",
                }}
              >
                {mippy?.mood?.map((v) => {
                  return (
                    <div
                      key={`mood_${v}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>{v}</div>
                    </div>
                  );
                })}
              </div>
              {mippy?.type && (
                <div
                  style={{
                    padding: "10px 0 0 0",
                  }}
                >
                  Art Type
                </div>
              )}
              <div
                style={{
                  padding: "0 0 0 20px",
                }}
              >
                {mippy?.type?.map((v) => {
                  return (
                    <div
                      key={`type_${v}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>{v}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className={"hide-scrollbar"}
            style={{
              backgroundColor: "white",
              overflowY: "scroll",
            }}
          >
            <div>Grade</div>
            <div
              style={{
                padding: "10px 20px 0",
              }}
            >
              {result.map((v) => {
                return (
                  <div
                    key={`result_${v.tag}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: `${v.match ? "blue" : "red"}`,
                    }}
                  >
                    <div>{v.tag}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
